@import url('https://fonts.googleapis.com/css?family=Lato:400,700,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@700&family=Varela&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@700,800,900&display=swap');

* {
  box-sizing: border-box;
}

html,
body,
#root {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

body {
  font-family: -apple-system, system-ui, sans-serif;
  font-size: 1.125em;
  font-weight: 400;
  line-height: 150%;
  color: rgba(0, 0, 0, 0.75);
}

h1 {
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 900;
  color: #272730;
  margin: 0;
  margin-bottom: 0.5rem;
  font-size: 86px;
  line-height: 1em;
  letter-spacing: -4px;
}

h2 {
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 900;
  color: #272730;
  font-size: 62px;
  line-height: 62px;
  letter-spacing: -2px;
  margin-top: 0;
  margin-bottom: 3rem;
}

h3 {
  font-style: normal;
  font-weight: 600;
  color: #272730;
  font-size: 20px;
  line-height: 20px;
  margin-top: 2rem;
  margin-bottom: 0;
}

p {
  font-size: 1.125em;
  font-weight: 400;
  line-height: 150%;
  color: rgba(0, 0, 0, 0.5);
  margin-top: 1rem;
  margin-bottom: 0;
  -webkit-font-smoothing: antialiased;
}

.page {
  padding: 0 15rem;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

.page.right {
  justify-content: flex-end;
  align-items: flex-end;
}

.page .block::before {
}

.half {
  width: 450px;
  max-width: 50%;
}

.grid-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  gap: 4rem;
  grid-template-areas: 'left right' 'content content';
}

.content {
  grid-area: content;
}

.left {
  grid-area: left;
}

.right {
  grid-area: right;
}

@keyframes changewidth {
  from {
    width: 0%;
  }
  to {
    width: 100%;
  }
}

.transition {
  display: inline-block;
  position: absolute !important;
  transition: all 0.5s ease;
  transition-property: opacity, transform;
}

.transition.vertical[hidden] {
  transition-delay: 0s;
  opacity: 0;
  transform: translate3d(0, -20px, 0) !important;
}

.transition.horizontal[hidden] {
  transition-delay: 0s;
  opacity: 0;
  transform: translate3d(30px, 0, 0) !important;
}

.logo {
  position: absolute;
  display: inline-block;
  top: 0;
  left: 100px;
  width: 60px;
  height: 100px;
  /*background-image: url('./awv.png');*/
  background-size: center;
  background-repeat: no-repeat;
  background-size: contain;
}

.menu.left {
  position: absolute;
  top: 4rem;
  left: 4rem;
}

.menu.right {
  position: absolute;
  top: 4rem;
  right: 4rem;
}

.menu.middle {
  position: absolute;
  top: 4rem;
  left: 4rem;
  right: 4rem;
  display: flex;
  justify-content: center;
}

.menu > span {
  padding-left: 1rem;
  padding-right: 1rem;
}

.menu > span:not(:last-child) {
  border-right: 1px solid rgba(0, 0, 0, 0.5);
}

.jumbo {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -40%, 0);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  min-width: 340px;
}

.jumbo h1 {
  width: 0;
}

.jumbo p {
  line-height: 200%;
  color: rgba(0, 0, 0, 0.75);
  text-align: left;
  margin-bottom: 1.5rem;
}

.jumbo p > span {
  position: relative;
  display: inline-block;
  overflow: hidden;
}

.jumbo p > a {
  position: relative;
  display: inline-block;
  overflow: hidden;
  text-decoration: none;
  background: #fcfabb;
  color: #272730;
  padding: 4px 10px;
  margin-right: 4px;
  border-radius: 5px;
  cursor: pointer;
  text-align: left;
  vertical-align: bottom;
  top: 3px;
}

.jumbo button {
  width: 240px;
  border: none;
  border-radius: 5px;
  padding: 1rem 2rem;
  margin: 0;
  margin-top: 1rem;
  text-decoration: none;
  background: black;
  color: white;
  font-family: Varela;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 29px;
  cursor: pointer;
  text-align: center;
}

@media (max-width: 580px) {
  .logo {
    width: 70px;
    height: 70px;
    top: 1.5rem;
    left: 2rem;
  }
  .menu.right {
    top: 2rem;
    right: 2rem;
  }
  .jumbo h1 {
    font-size: 46px;
    line-height: 46px;
    letter-spacing: 26px;
    margin-left: 26px;
  }
}

.scrollArea {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  overflow: auto;
}
